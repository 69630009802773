import { Container } from "../../pages/SOAPNotes/Admin/helpers";
import { InfoRow, Loader } from "../StyledComponents";
import Text from "../../shared/uiComponents/Text";
import AuthorizationInfo from "./authorizationsInfo";

import { useSelector } from "../../redux/store";

const Dashboard = () => {
  const user = useSelector((state) => state.account.user);
  const role = useSelector((state) => state.account.role);
  const loadingAccount = useSelector((state) => state.account.loading);

  return (
    <Container style={{ maxWidth: "350px" }}>
      <Text title={"Account Information"} size="mediumBold" />
      {!!user && !!user.id && !loadingAccount && (
        <>
          <InfoRow>
            <Text title={"Name:"} size="smallBold" />
            <Text title={`${user.firstName} ${user.lastName}`} />
          </InfoRow>
          <InfoRow>
            <Text title={"Email:"} size="smallBold" />
            <Text title={user.email} />
          </InfoRow>
        </>
      )}
      {!!role && !!role.id && !loadingAccount && (
        <>
          <InfoRow>
            <Text title={"Role:"} size="smallBold" />
            <Text title={role.name} />
          </InfoRow>
          <InfoRow>
            <Text title={"Section:"} size="smallBold" />
            <Text title={role.section.name} />
          </InfoRow>
        </>
      )}
      <AuthorizationInfo />
      {loadingAccount && <Loader />}
    </Container>
  );
};

export default Dashboard;
