import { useState } from "react";
import dayjs from "dayjs";
import { InfoOutlined } from "@mui/icons-material";

import { CustomAlert, Text } from "../../shared/uiComponents";
import { InfoRow } from "../StyledComponents";
import ExpireInfo from "./expiredInfo";

import { AvailableHoursProperties } from "../../redux/API/ClientAPIHelpers/userClientProperties";

const AuthorizationDetailedInfo = ({
  item,
}: {
  item: AvailableHoursProperties;
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <InfoOutlined
        color={dayjs().isAfter(item.endDate) ? "error" : "success"}
        onClick={() => setOpen(true)}
      />
      <CustomAlert
        open={open}
        onClose={() => setOpen(false)}
        Content={() => <AuthorizationMoreInfoContent info={item} />}
      />
    </>
  );
};

const AuthorizationMoreInfoContent = ({
  info,
}: {
  info: AvailableHoursProperties;
}) => {
  const {
    sessionType,
    frequency,
    hoursPerWeek,
    remainingHoursPerWeek,
    totalHours,
    remainingTotalHours,
    startDate,
    endDate,
  } = info;

  return (
    <>
      <InfoRow>
        <Text title={"Session type:"} />
        <Text title={sessionType.name} />
      </InfoRow>
      <InfoRow>
        <Text title={"Frequency type:"} />
        <Text title={frequency.name} />
      </InfoRow>
      {frequency.id === 1 && !!hoursPerWeek && (
        <InfoRow>
          <Text title={"Remaining:"} />
          <Text title={`${remainingHoursPerWeek}/${hoursPerWeek}`} />
        </InfoRow>
      )}
      {!!totalHours && (
        <InfoRow>
          <Text title={"Total:"} />
          <Text title={`${remainingTotalHours}/${totalHours}`} />
        </InfoRow>
      )}
      <InfoRow>
        <Text title={"Start date:"} />
        <Text title={dayjs(startDate).format("MM-DD-YYYY")} />
      </InfoRow>
      <InfoRow>
        <Text title={"End date:"} />
        <Text title={dayjs(endDate).format("MM-DD-YYYY")} />
      </InfoRow>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <ExpireInfo endDate={endDate} />
      </div>
    </>
  );
};

export default AuthorizationDetailedInfo;
