import { FC } from "react";
import dayjs from "dayjs";
import { TableBody as Body, TableCell, TableRow } from "@mui/material";

import { Text } from "../../shared/uiComponents";
import AuthorizationListItem from "./authorizationListItem";

import { AvailableHoursForDashboardProperties } from "../../redux/API/ClientAPIHelpers/userClientProperties";

interface RowRendererProperties {
  data: Array<AvailableHoursForDashboardProperties>;
}

export const TableBody: FC<RowRendererProperties> = ({ data }) => {
  return (
    <Body>
      <TableRow>
        <TableCell />
        <TableCell>
          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <Text title={"Start Date"} size="tinyBold" className="width150" />
            <Text title={"End Date"} size="tinyBold" className="width150" />
          </div>
        </TableCell>
      </TableRow>
      {data
        .filter((x) =>
          x.availableHours.some((x) => !dayjs().isAfter(x.endDate))
        )
        .map((row, index) => (
          <TableRow key={index}>
            <TableCell>
              <Text
                title={row.client.fullName as string}
                className="paddingLeft16"
              />
            </TableCell>
            <TableCell>
              {!!row.availableHours &&
                [...row.availableHours]
                  .filter((x) => !dayjs().isAfter(x.endDate))
                  .sort((a, b) => {
                    const c = new Date(a.startDate);
                    const d = new Date(b.startDate);
                    if (c < d) return 1;
                    if (c > d) return -1;
                    return 0;
                  })
                  .map((item, index) => (
                    <AuthorizationListItem key={index} item={item} />
                  ))}
              {!!row.availableHours &&
                [...row.availableHours]
                  .filter((x) => dayjs().isAfter(x.endDate))
                  .sort((a, b) => {
                    const c = new Date(a.startDate);
                    const d = new Date(b.startDate);
                    if (c < d) return 1;
                    if (c > d) return -1;
                    return 0;
                  })
                  .map((item, index) => (
                    <AuthorizationListItem key={index} item={item} />
                  ))}
            </TableCell>
          </TableRow>
        ))}
      {data
        .filter((x) =>
          x.availableHours.every((x) => dayjs().isAfter(x.endDate))
        )
        .map((row, index) => (
          <TableRow key={index}>
            <TableCell>
              <Text
                title={row.client.fullName as string}
                className="paddingLeft16"
              />
            </TableCell>
            <TableCell>
              {!!row.availableHours &&
                [...row.availableHours]
                  .filter((x) => !dayjs().isAfter(x.endDate))
                  .sort((a, b) => {
                    const c = new Date(a.startDate);
                    const d = new Date(b.startDate);
                    if (c < d) return 1;
                    if (c > d) return -1;
                    return 0;
                  })
                  .map((item, index) => (
                    <AuthorizationListItem key={index} item={item} />
                  ))}
              {!!row.availableHours &&
                [...row.availableHours]
                  .filter((x) => dayjs().isAfter(x.endDate))
                  .sort((a, b) => {
                    const c = new Date(a.startDate);
                    const d = new Date(b.startDate);
                    if (c < d) return 1;
                    if (c > d) return -1;
                    return 0;
                  })
                  .map((item, index) => (
                    <AuthorizationListItem key={index} item={item} />
                  ))}
            </TableCell>
          </TableRow>
        ))}
    </Body>
  );
};
