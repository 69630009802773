import { FC } from "react";
import { TableBody as Body, TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";

import { HeaderProperties } from "../../../../shared/uiComponents/Table/tableProperties";
import { TableNoData, Text } from "../../../../shared/uiComponents";
import ExpireInfo from "../../../../components/Dashboard/expiredInfo";
import EditAuthorization from "./EditInsuranceAuthorization";
import AuthorizationDetails from "./authorizationDetails";

import { ActiveAuthorizationProperties } from "../../../../redux/API/ClientAPIHelpers/insuranceAuthorizationProperties";
import DeleteAuthorization from "./deleteInsuranceAuthorization";

interface RowRendererProperties {
  data: Array<ActiveAuthorizationProperties>;
}

export const headers: Array<HeaderProperties> = [
  { id: "0", name: "Authorization details" },
  { id: "1", name: "Authorization information" },
  { id: "2", name: "Status" },
];

export const headersAll: Array<HeaderProperties> = [
  { id: "1", name: "Authorization information" },
  { id: "2", name: "Status" },
  { id: "0", name: "Authorization details" },
];

interface RowRendererProperties {
  data: Array<ActiveAuthorizationProperties>;
}

export const TableBody: FC<RowRendererProperties> = ({ data }) => {
  if (!data || !data.length) {
    return <TableNoData spanScope={headers.length} />;
  }

  return (
    <Body>
      <TableRow>
        <TableCell>
          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <div style={{ width: "250px" }}>
              <Text title={"Session name"} size="tinyBold" />
            </div>
            <div style={{ width: "50px" }}>
              <Text title={"Total hours"} size="tinyBold" />
            </div>
            <div style={{ width: "50px" }}>
              <Text title={"Weekly hours"} size="tinyBold" />
            </div>
            <div style={{ width: "100px" }}>
              <Text title={"Unassigned hours"} size="tinyBold" />
            </div>
            <div style={{ width: "100px" }}>
              <Text title={"Assigned hours"} size="tinyBold" />
            </div>
          </div>
        </TableCell>
        <TableCell>
          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <div style={{ width: "100px" }}>
              <Text title={"Type"} size="tinyBold" />
            </div>
            <div style={{ width: "100px" }}>
              <Text title={"Start date"} size="tinyBold" />
            </div>
            <div style={{ width: "100px" }}>
              <Text title={"End date"} size="tinyBold" />
            </div>
          </div>
        </TableCell>
        <TableCell />
      </TableRow>
      {data.map((row, index) => (
        <TableRow key={index}>
          <TableCell>
            <AuthorizationDetails
              data={row.authorizationDetails}
              authorizationId={row.id}
            />
          </TableCell>
          <TableCell>
            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <div style={{ width: "100px" }}>
                <Text title={row.type.name} size="tiny" />
              </div>
              <div style={{ width: "100px" }}>
                <Text
                  title={dayjs(row.startDate).format("MM/DD/YYYY")}
                  size="tiny"
                />
              </div>
              <div style={{ width: "100px" }}>
                <Text
                  title={dayjs(row.endDate).format("MM/DD/YYYY")}
                  size="tiny"
                />
              </div>
            </div>
          </TableCell>
          <TableCell>
            <ExpireInfo endDate={row.endDate} />
          </TableCell>
        </TableRow>
      ))}
    </Body>
  );
};

export const TableBodyAll: FC<RowRendererProperties> = ({ data }) => {
  if (!data || !data.length) {
    return <TableNoData spanScope={headersAll.length} />;
  }

  return (
    <>
      <Body>
        <TableRow>
          <TableCell>
            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <div style={{ width: "100px" }}>
                <Text title={"Type"} size="tinyBold" />
              </div>
              <div style={{ width: "100px" }}>
                <Text title={"Start date"} size="tinyBold" />
              </div>
              <div style={{ width: "100px" }}>
                <Text title={"End date"} size="tinyBold" />
              </div>
            </div>
          </TableCell>
          <TableCell />
          <TableCell>
            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <div style={{ width: "200px" }}>
                <Text title={"Session name"} size="tinyBold" />
              </div>
              <div style={{ width: "50px" }}>
                <Text title={"Total hours"} size="tinyBold" />
              </div>
              <div style={{ width: "50px" }}>
                <Text title={"Weekly hours"} size="tinyBold" />
              </div>
              <div style={{ width: "100px" }}>
                <Text title={"Frequency type"} size="tinyBold" />
              </div>
              <div style={{ width: "100px" }}>
                <Text title={"Edit"} size="tinyBold" />
              </div>
            </div>
          </TableCell>
        </TableRow>
        {data.map((row, index) => (
          <TableRow key={index}>
            <TableCell>
              <div
                style={{ display: "flex", gap: "8px", alignItems: "center" }}
              >
                <div style={{ width: "100px" }}>
                  <Text title={row.type.name} size="tiny" />
                </div>
                <div style={{ width: "100px" }}>
                  <Text
                    title={dayjs(row.startDate).format("MM/DD/YYYY")}
                    size="tiny"
                  />
                </div>
                <div style={{ width: "100px" }}>
                  <Text
                    title={dayjs(row.endDate).format("MM/DD/YYYY")}
                    size="tiny"
                  />
                </div>
              </div>
            </TableCell>
            <TableCell>
              <ExpireInfo endDate={row.endDate} />
            </TableCell>
            <TableCell style={{ display: "flex" }}>
              <div>
                {row.authorizationDetails.map((x) => (
                  <div style={{ display: "flex", gap: "8px" }}>
                    <div style={{ width: "200px" }}>{x.sessionType.name}</div>
                    <div style={{ width: "50px" }}>{x.totalHours}</div>
                    <div style={{ width: "50px" }}>
                      {row.type.id !== 1 && !!x.hoursPerWeek && x.hoursPerWeek}
                    </div>
                    <div style={{ width: "100px" }}>{x.frequency.name}</div>
                  </div>
                ))}
              </div>
              {row.isEditable && <EditAuthorization authorizationData={row} />}
              <DeleteAuthorization authorizationData={row} />
            </TableCell>
          </TableRow>
        ))}
      </Body>
    </>
  );
};

export const TableBodyBCBA: FC<RowRendererProperties> = ({ data }) => {
  if (!data || !data.length) {
    return <TableNoData spanScope={headersAll.length} />;
  }

  return (
    <>
      <Body>
        <TableRow>
          <TableCell>
            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <div style={{ width: "100px" }}>
                <Text title={"Type"} size="tinyBold" />
              </div>
              <div style={{ width: "100px" }}>
                <Text title={"Start date"} size="tinyBold" />
              </div>
              <div style={{ width: "100px" }}>
                <Text title={"End date"} size="tinyBold" />
              </div>
            </div>
          </TableCell>
          <TableCell />
          <TableCell>
            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <div style={{ width: "200px" }}>
                <Text title={"Session name"} size="tinyBold" />
              </div>
              <div style={{ width: "75px" }}>
                <Text title={"Hours per week"} size="tinyBold" />
              </div>
              <div style={{ width: "50px" }}>
                <Text title={"Total hours"} size="tinyBold" />
              </div>
            </div>
          </TableCell>
        </TableRow>
        {data.map((row, index) => (
          <TableRow key={index}>
            <TableCell padding="none">
              <div
                style={{ display: "flex", gap: "8px", alignItems: "center" }}
              >
                <div style={{ width: "100px" }}>
                  <Text title={row.type.name} size="tiny" />
                </div>
                <div style={{ width: "100px" }}>
                  <Text
                    title={dayjs(row.startDate).format("MM/DD/YYYY")}
                    size="tiny"
                  />
                </div>
                <div style={{ width: "100px" }}>
                  <Text
                    title={dayjs(row.endDate).format("MM/DD/YYYY")}
                    size="tiny"
                  />
                </div>
              </div>
            </TableCell>
            <TableCell>
              <ExpireInfo endDate={row.endDate} />
            </TableCell>
            <TableCell>
              {row.authorizationDetails.map((x) => (
                <div style={{ display: "flex", gap: "8px" }}>
                  <div style={{ width: "200px" }}>{x.sessionType.name}</div>
                  {x.sessionType.name.includes("Assessment") ? ( // "1" - Assessment type
                    <div style={{ width: "75px" }}>
                      {x.hoursPerWeek.toFixed(2)}
                    </div>
                  ) : (
                    <div style={{ width: "75px" }} />
                  )}
                  <div style={{ width: "50px" }}>{x.totalHours}</div>
                </div>
              ))}
            </TableCell>
          </TableRow>
        ))}
      </Body>
    </>
  );
};
