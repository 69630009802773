import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { DispatchProperties, useSelector } from "../../redux/store";
import { getAvailableHoursForDashboard } from "../../redux/State/clientSlice/userClientSlice";
import { CustomTable } from "../../shared/uiComponents";
import { TableBody } from "./tableInfo";

const AuthorizationInfo = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const user = useSelector((state) => state.account.user);
  const role = useSelector((state) => state.account.role);
  const hoursInfo = useSelector((state) => state.userClient.availableHours);
  const loading = useSelector((state) => state.userClient.loading);

  useEffect(() => {
    if (!user.id || !role) return;
    const userId = user.id;
    const type = role.section.id;
    dispatch(getAvailableHoursForDashboard({ userId, type }));
  }, [user, role, dispatch]);

  if(!hoursInfo || !hoursInfo.length) return null;

  return (
    <CustomTable
      headers={[
        { id: "1", name: "Name" },
        { id: "2", name: "Authorization" },
      ]}
      data={{ query: hoursInfo }}
      TableBody={TableBody}
      loading={loading}
      hasPagination={false}
    />
  );
};

export default AuthorizationInfo;
