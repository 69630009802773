import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ErrorProperties } from "../../API/identityAPIProperties";
import API from "../../API/Main/API";
import {
    ActiveAuthorizationProperties,
    UpdateAllocatedHoursProperties,
    AddAuthorizationProperties,
    AuthorizationDetailsProperties,
    GetAvailableHoursDetailProperties,
    InsuranceAuthorizationsProperties,
    GetInsuranceAuthorizationsProperties,
    AuthorizationProperties,
    CalculatedHoursRangeProperties,
    UserAllocatedHoursDetailProperties,
    GetCalculatedHoursRangeProperties,
    InsuranceAuthorizationSessionTypeProperties,
    GetAllocatedHoursDetailAuthorizationProperties,
    InsuranceAllocatedAuthorizationDetailsProperties,
    UpdateInsuranceAuthorization,
    GetUtilizationReportProperties,
} from "../../API/ClientAPIHelpers/insuranceAuthorizationProperties";

interface InsuranceAuthorizationStateProperties {
    loading: boolean,
    authorizationDetails: Array<ActiveAuthorizationProperties> | null,
    allocatedHoursDetails: Array<AuthorizationDetailsProperties>,
    insuranceAuthorizationSessionTypes: Array<InsuranceAuthorizationSessionTypeProperties>,
    allInsuranceAuthorizations: InsuranceAuthorizationsProperties,
    availableHoursDetails: Array<AuthorizationDetailsProperties>,
    error: ErrorProperties,
}

const initialState: InsuranceAuthorizationStateProperties = {
    loading: false,
    authorizationDetails: null,
    allocatedHoursDetails: [],
    availableHoursDetails: [],
    insuranceAuthorizationSessionTypes: [],
    allInsuranceAuthorizations: {
        query: null,
    },
    error: {
        status: 0,
        title: ""
    }
}

export const getInsuranceAuthorizationSessionTypes = createAsyncThunk(
    '/authorization/session-types',
    async (type: string, thunkAPI) => {
        const response = await API.ClientAPI.InsuranceAuthorization.getInsuranceAuthorizationSessionTypes(type);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getHourPerWeekInsuranceAuthorizationsData = createAsyncThunk(
    '/authorization/hour-per-week/data',
    async (data: GetCalculatedHoursRangeProperties, thunkAPI) => {
        const response = await API.ClientAPI.InsuranceAuthorization.getInsuranceAuthorizationPerWeekData(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as CalculatedHoursRangeProperties;
    }
)

export const getActiveInsuranceAuthorizations = createAsyncThunk(
    '/authorization/active',
    async (data: { clientId: string, userId?: string }, thunkAPI) => {
        const response = await API.ClientAPI.InsuranceAuthorization.getActiveInsuranceAuthorizations(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<ActiveAuthorizationProperties>;
    }
)

export const getInsuranceAuthorizations = createAsyncThunk(
    '/authorization/all',
    async (data: GetInsuranceAuthorizationsProperties, thunkAPI) => {
        const response = await API.ClientAPI.InsuranceAuthorization.getInsuranceAuthorizations(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as InsuranceAuthorizationsProperties;
    }
)

export const saveInsuranceAuthorizations = createAsyncThunk(
    '/authorization/save',
    async ({ clientId, data }: { clientId: string, data: AddAuthorizationProperties }, thunkAPI) => {
        const response = await API.ClientAPI.InsuranceAuthorization.saveInsuranceAuthorizations(clientId, data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const saveAssessmentInsuranceAuthorizations = createAsyncThunk(
    '/authorization/save/assessment',
    async ({ clientId, data }: { clientId: string, data: AuthorizationProperties }, thunkAPI) => {
        const response = await API.ClientAPI.InsuranceAuthorization.saveAssessmentInsuranceAuthorizations(clientId, data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const saveTreatmentInsuranceAuthorizations = createAsyncThunk(
    '/authorization/save/treatment',
    async ({ clientId, data }: { clientId: string, data: AuthorizationProperties }, thunkAPI) => {
        const response = await API.ClientAPI.InsuranceAuthorization.saveTreatmentAuthorizations(clientId, data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getAvailableHoursDetails = createAsyncThunk(
    '/authorization/available-hours/details',
    async (data: GetAvailableHoursDetailProperties, thunkAPI) => {
        const response = await API.ClientAPI.InsuranceAuthorization.getAvailableHoursDetails(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<AuthorizationDetailsProperties>;
    }
)

export const getAllocatedHoursDetailsUser = createAsyncThunk(
    '/authorization/allocated-hours/details/user',
    async (data: UserAllocatedHoursDetailProperties, thunkAPI) => {
        const response = await API.ClientAPI.InsuranceAuthorization.getAllocatedHoursDetailsUser(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<AuthorizationDetailsProperties>;
    }
)


export const getAllocatedHoursAuthorizationDetails = createAsyncThunk(
    '/authorization/allocated-hours/details',
    async (data: GetAllocatedHoursDetailAuthorizationProperties, thunkAPI) => {
        const response = await API.ClientAPI.InsuranceAuthorization.getAllocatedHoursAuthorizationDetails(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<InsuranceAllocatedAuthorizationDetailsProperties>;
    }
)

export const updateAllocatedHours = createAsyncThunk(
    '/authorization/allocated-hours/update',
    async (data: UpdateAllocatedHoursProperties, thunkAPI) => {
        const response = await API.ClientAPI.InsuranceAuthorization.updateAllocatedHours(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const updateAuthorization = createAsyncThunk(
    '/authorization/edit/update',
    async (data: UpdateInsuranceAuthorization, thunkAPI) => {
        const response = await API.ClientAPI.InsuranceAuthorization.editAuthorization(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getUtilizationReport = createAsyncThunk(
    '/authorization/utilization/report',
    async (data: GetUtilizationReportProperties, thunkAPI) => {
        const response = await API.ClientAPI.InsuranceAuthorization.getUtilizationReport(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as {
            file: Blob,
            fileName: string,
        };
    }
)

export const deleteAuthorization = createAsyncThunk(
    '/authorization/delete',
    async (data: GetAvailableHoursDetailProperties, thunkAPI) => {
        const response = await API.ClientAPI.InsuranceAuthorization.deleteAuthorization(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

const insuranceAuthorizationSlice = createSlice({
    name: 'insuranceAuthorization',
    initialState,
    reducers: {
        clearActiveAuthorization(state) {
            state.authorizationDetails = initialState.authorizationDetails;
        }
    },
    extraReducers: (builder) => {
        //deleteAuthorization
        builder.addCase(deleteAuthorization.pending, (state) => {
            state.loading = true;
            state.error = initialState.error;
        })
        builder.addCase(deleteAuthorization.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(deleteAuthorization.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getUtilizationReport
        builder.addCase(getUtilizationReport.pending, (state) => {
            state.loading = true;
            state.error = initialState.error;
        })
        builder.addCase(getUtilizationReport.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(getUtilizationReport.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getInsuranceAuthorizationSessionTypes
        builder.addCase(getInsuranceAuthorizationSessionTypes.pending, (state) => {
            // state.loading = true;
            state.error = initialState.error;
        })
        builder.addCase(getInsuranceAuthorizationSessionTypes.fulfilled, (state, action) => {
            // state.loading = false;
            state.insuranceAuthorizationSessionTypes = action.payload;
        })
        builder.addCase(getInsuranceAuthorizationSessionTypes.rejected, (state, action) => {
            // state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getActiveInsuranceAuthorizations
        builder.addCase(getActiveInsuranceAuthorizations.pending, (state) => {
            state.loading = true;
            state.error = initialState.error;
        })
        builder.addCase(getActiveInsuranceAuthorizations.fulfilled, (state, action) => {
            state.loading = false;
            state.authorizationDetails = action.payload;
        })
        builder.addCase(getActiveInsuranceAuthorizations.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getInsuranceAuthorizations
        builder.addCase(getInsuranceAuthorizations.pending, (state) => {
            state.loading = true;
            state.error = initialState.error;
        })
        builder.addCase(getInsuranceAuthorizations.fulfilled, (state, action) => {
            state.loading = false;
            state.allInsuranceAuthorizations = action.payload;
        })
        builder.addCase(getInsuranceAuthorizations.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getAllocatedHoursDetailsUser
        builder.addCase(getAllocatedHoursDetailsUser.pending, (state) => {
            state.loading = true;
            state.error = initialState.error;
        })
        builder.addCase(getAllocatedHoursDetailsUser.fulfilled, (state, action) => {
            state.loading = false;
            state.allocatedHoursDetails = action.payload;
        })
        builder.addCase(getAllocatedHoursDetailsUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getAvailableHoursDetails
        builder.addCase(getAvailableHoursDetails.pending, (state) => {
            state.loading = true;
            state.error = initialState.error;
        })
        builder.addCase(getAvailableHoursDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.availableHoursDetails = action.payload;
        })
        builder.addCase(getAvailableHoursDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //saveAssessmentInsuranceAuthorizations
        builder.addCase(saveAssessmentInsuranceAuthorizations.pending, (state) => {
            state.loading = true;
            state.error = initialState.error;
        })
        builder.addCase(saveAssessmentInsuranceAuthorizations.fulfilled, (state, action) => {
            state.loading = false;
        })
        builder.addCase(saveAssessmentInsuranceAuthorizations.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //saveTreatmentInsuranceAuthorizations
        builder.addCase(saveTreatmentInsuranceAuthorizations.pending, (state) => {
            state.loading = true;
            state.error = initialState.error;
        })
        builder.addCase(saveTreatmentInsuranceAuthorizations.fulfilled, (state, action) => {
            state.loading = false;
        })
        builder.addCase(saveTreatmentInsuranceAuthorizations.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
    }
})

export const { clearActiveAuthorization } = insuranceAuthorizationSlice.actions;
export default insuranceAuthorizationSlice.reducer;