import dayjs from "dayjs";

import { Text } from "../../shared/uiComponents";

import { AvailableHoursProperties } from "../../redux/API/ClientAPIHelpers/userClientProperties";
import AuthorizationDetailedInfo from "./authorizationDetailedInfo";

const AuthorizationListItem = ({
  item,
}: {
  item: AvailableHoursProperties;
}) => (
  <div
    style={{
      display: "flex",
      gap: "8px",
      alignItems: "center",
      paddingBottom: "8px",
    }}
  >
    <Text
      title={dayjs(item.startDate).format("MM-DD-YYYY")}
      className="width150"
    />
    <Text
      title={dayjs(item.endDate).format("MM-DD-YYYY")}
      className="width150"
    />
    <AuthorizationDetailedInfo item={item} />
  </div>
);

export default AuthorizationListItem;
